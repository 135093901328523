<div id="main-container" class="fade-in">
    <div class="inner-container">
        <div class="inputs-container">
            <div class="central-container">
                <div class="eu-align_center">
                    <img src="/assets/icons/logo.svg" alt="Logo do Stone Partner Program" />
                </div>
                <strong class="eu-mt_4">Gerencie a nossa parceria e escale a sua operação</strong>
                <div class="guru-logo-container eu-mt_4" *ngIf="showInputUserPasswordFrame()">
                    <div class="img-container eu-pl_4">
                        <img src="/assets/icons/guru-logo.svg" alt="Logo do Mascote do Partner Hub" />
                    </div>
                    <p class="eu-pl_2" style="font-weight: 700">
                        {{ accountUserEmail }} <br />
                        Que bom te ter de volta!
                    </p>
                </div>

                <div class="eu-mt_4 box-container">
                    <div *ngIf="showInputUserEmailFrame()">
                        <div class="emd-form-group">
                            <label for="email" class="emd-label"> E-mail </label>
                            <input
                                id="email"
                                type="email"
                                placeholder="Digite seu e-mail"
                                required="required"
                                autofocus="autofocus"
                                class="emd-input"
                                [(ngModel)]="accountUserEmail"
                                (keypress)="inputEnterKeyAction($event, 'email')"
                            />
                        </div>
                        <div class="el-chunk"></div>
                        <div class="emd-text _align_right eu-mb_3">
                            <button
                                type="submit"
                                tabindex="0"
                                class="emd-btn _color_primary _block_mobile"
                                [disabled]="!accountUserEmail"
                                (click)="continueToPassword()"
                            >
                                <span *ngIf="!signInLoaderSetVisible">Continuar</span>
                                <span *ngIf="signInLoaderSetVisible">
                                    <div class="emd-loader">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </span>
                            </button>
                        </div>
                    </div>

                    <div [hidden]="!showInputUserPasswordFrame()" class="password-box">
                        <div class="emd-form-group">
                            <label for="password" class="emd-label"> Senha </label>
                            <div class="emd-input-group">
                                <input
                                    #inputUserPasswordFrame
                                    id="password"
                                    type="password"
                                    [type]="showPassword ? 'text' : 'password'"
                                    placeholder="Digite sua senha"
                                    required="required"
                                    minlength="6"
                                    class="emd-input fs-exclude"
                                    [(ngModel)]="accountUserPassword"
                                    (keypress)="inputEnterKeyAction($event, 'password')"
                                />
                                <a
                                    href="javascript:void(0)"
                                    tabindex="-1"
                                    class="emd-input-icon__link _align_right emd-link _color_primary"
                                    (click)="showPassword = !showPassword"
                                >
                                    <i *ngIf="!showPassword" class="fa fa-eye"></i>
                                    <i *ngIf="showPassword" class="fa fa-eye-slash"></i>
                                </a>
                            </div>
                            <a class="emd-input-link emd-link _color_primary" tabindex="-1" (click)="resetPasswordSetVisibleTrue()"
                                >Esqueci minha senha</a
                            >
                        </div>
                        <div *ngIf="isRecaptcha">
                            <form #captchaProtectedForm="ngForm">
                                <re-captcha
                                    [(ngModel)]="formModel.captcha"
                                    name="captcha"
                                    #captchaControl="ngModel"
                                    (resolved)="resolved($event)"
                                    (error)="error($event)"
                                    siteKey="6LcH6-wUAAAAAObNxBeKH9hZspRcz-_v9Qj5hq7k"
                                >
                                </re-captcha>
                            </form>
                        </div>
                        <div class="emd-text _align_right eu-mb_3">
                            <br />
                            <br />
                            <a (click)="inputPasswordSetVisible = false" class="emd-btn _color_primary _type_text" tabindex="-1">Voltar</a>
                            <button
                                type="submit"
                                tabindex="0"
                                class="emd-btn _color_primary"
                                [disabled]="!accountUserPassword || (isRecaptcha && !captchaResponse)"
                                (click)="authorizeAccountUser()"
                            >
                                <span *ngIf="!signInLoaderSetVisible">Entrar</span>
                                <span *ngIf="signInLoaderSetVisible">
                                    <div class="emd-loader">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </span>
                            </button>
                        </div>
                    </div>

                    <!--Reset da senha-->
                    <div *ngIf="resetPasswordSetVisible">
                        <div>
                            <div class="el-chunk">
                                <h1 class="emd-text _size_subpage _color_default">Esqueceu sua senha?</h1>
                            </div>
                            <div class="el-chunk"><i class="fa fa-user-circle-o"></i> {{ accountUserEmail }}</div>
                            <div class="el-chunk"></div>
                            <div class="el-chunk">
                                <p *ngIf="!resetPasswordSentMessageSetVisible">
                                    Confira os seus dados e clique em "Resetar senha". Você receberá um e-mail com as instruções para a
                                    troca de senha na sua caixa de entrada.
                                </p>

                                <p *ngIf="resetPasswordSentMessageSetVisible">
                                    Verifique sua caixa de entrada.<br />
                                    <strong>Não encontrou o e-mail?</strong> Procure na caixa de spam ou envie novamente.
                                </p>
                            </div>
                        </div>
                        <div class="emd-text _align_right eu-mb_3">
                            <a class="emd-btn _color_primary _type_text" tabindex="-1" (click)="resetPasswordSetVisible = false">Voltar</a>
                            <button type="submit" tabindex="0" class="emd-btn _color_primary" (click)="sendResetAccountUserPassword()">
                                <span *ngIf="!signInLoaderSetVisible && !resetPasswordSentMessageSetVisible">Resetar senha</span>
                                <span *ngIf="resetPasswordSentMessageSetVisible && !signInLoaderSetVisible">Reenviar e-mail</span>
                                <span *ngIf="signInLoaderSetVisible">
                                    <div class="emd-loader">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </span>
                            </button>
                        </div>
                    </div>
                    <!-- Mensagem de envio do pré-registro de usuários antigos que não usam ID Pagar.me -->
                    <div *ngIf="preRegisterOldUserMessageSetVisible">
                        <div class="emd-container__content">
                            <div class="el-chunk">
                                <h1 class="emd-text _size_subpage _color_default">Verificação de Segurança.</h1>
                            </div>
                            <div class="el-chunk"><i class="fa fa-user-circle-o"></i> {{ accountUserEmail }}</div>
                            <div class="el-chunk"></div>
                            <div class="el-chunk">
                                <p>
                                    Para garantir maior segurança em seu acesso, realizaremos uma verificação do seu usuário.<br /><br />
                                    É bem simples! Durante a verificação será necessário que você confirme ou altere sua senha atual para
                                    prosseguir com o acesso ao Partner Hub.<br /><br />
                                    Verifique sua caixa de entrada.<br />
                                    <strong>Não encontrou o e-mail?</strong> Procure na caixa de spam ;)
                                </p>
                            </div>
                        </div>
                        <div class="emd-text _align_right eu-mb_3">
                            <a class="emd-btn _color_primary _type_text" tabindex="-1" (click)="preRegisterOldUserMessageSetVisible = false"
                                >Voltar</a
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="configure2faIsVisible">
                <app-two-factor-configuration-page
                    [authCode]="configuration2faAuth ?? ''"
                    [authType]="configuration2faAuthType"
                    [accountUserEmail]="accountUserEmail"
                    (onBackToLogin)="handleBackToLogin()"
                    (onFinish2faConfiguration)="handleFinish2faConfiguration()"
                ></app-two-factor-configuration-page>
            </div>
            <div *ngIf="require2faSetVisible">
                <app-two-factor-login
                    (onBackToLoginFlow)="handleBackToLogin()"
                    (onAuthorizeAccountUser)="onAuthorizeAccountUser($event)"
                    (onHandleLoginProblems)="handleLoginProblems()"
                    [hasEnteredInvalidCode]="invalid2faCodeFeedback"
                    [twoFactorCode]="code2fa ?? ''"
                    [signInLoaderIsVisible]="signInLoaderSetVisible"
                ></app-two-factor-login>
            </div>
            <div *ngIf="reset2faSetVisible">
                <app-login-problems-page
                    [authCode]="configuration2faAuth ?? ''"
                    [accountUserEmail]="accountUserEmail"
                    (onRetryTypeAuthCode)="handleRetryTypeAuthCode()"
                    (onSuccessRecoveryAccount)="restartLoginProcess()"
                ></app-login-problems-page>
            </div>
            <div *ngIf="forceResetSms2fa">
                <app-two-factor-force-sms-reset-page
                    [authCode]="configuration2faAuth ?? ''"
                    (onResetLogin)="restartLoginProcess()"
                ></app-two-factor-force-sms-reset-page>
            </div>
        </div>
    </div>

    <footer></footer>
</div>
