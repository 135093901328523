import { inject } from '@angular/core'
import { Router, type CanActivateFn } from '@angular/router'
import { ManageLoggedUser } from '../../../legacy/authentication/services/manage-logged-user.service'
import { AccessService } from '../../../legacy/core/services/access-service.service'

export const sallesFunnelGuard: CanActivateFn = (route, state) => {
    const loggedUserService = inject(ManageLoggedUser)
    const accessService = inject(AccessService)
    const router = inject(Router)

    if (loggedUserService.isLoggedIn()) {
        const hasPermission = accessService.hasRoles(['create-lead-internal', 'create-lead-sales', 'create-lead-owner'])

        if (!hasPermission) {
            router.navigate(['/not-authorized'])
        }

        return hasPermission
    }

    router.navigate(['/'])
    return false
}
