import { Injectable } from '@angular/core'
import { NavigationTree } from '../types/navigation'
import { Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { navigationNodes } from '../statics/navigation-data'

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private navigationTree: NavigationTree = {
        nodes: navigationNodes,
    }

    constructor(
        private router: Router,
        private toasterService: ToastrService,
    ) { }

    public navigateTo(url: string) {
        this.router.navigateByUrl(url).catch((error) => {
            console.log(error);

            this.toasterService.error(`Falha ao tentar navegar para a rota ${url}`)
        })
    }

    public navigate(url: string, routeParam: string) {
        this.router.navigate([url, routeParam])
    }

    public getNavigationTree(): NavigationTree {
        return this.navigationTree
    }
}
