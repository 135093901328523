import { Routes } from '@angular/router'
import { CreateLeadRouteGuard } from './authentication/guards/create-lead'
import { LandingPagesPanelRouteGuard } from './authentication/guards/landing-pages-panel'
import { ProfileComponent } from './features/config/components/profile/profile.component'
import { UsersComponent } from './features/config/components/users/users.component'
import { ProfileGuard } from './features/config/guards/profile.guard'
import { UsersGuard } from './features/config/guards/users.guard'
import { IntegrationGuard } from './features/my-integration/guards/integration.guard'
import { PriceManagementGuard } from './features/price-management/guards/price-management.guard'
import { AccountUserDefinePasswordComponent } from './legacy/authentication/components/define-password/account-user-define-password.component'
import { LoggedUser } from './legacy/authentication/components/logged-user/logged-user.component'
import { AccountUserRouteGuard } from './legacy/authentication/guards/account-users/account-user-route-guard'
import { ApplicationApprovalRouteGuard } from './legacy/authentication/guards/application-approval/application-approval-route-guard'
import { ApplicationRouteGuard } from './legacy/authentication/guards/application/application-route-guard'
import { CreateStoneAccountRouteGuard } from './legacy/authentication/guards/create-stone-account/create-stone-account-route-guard'
import { DashboardRouteGuard } from './legacy/authentication/guards/dashboard/dashboard-route-guard'
import { DocumentsRouteGuard } from './legacy/authentication/guards/documents/documents-guard'
import { HomeRouteGuard } from './legacy/authentication/guards/home/home-route-guard.service'
import { ModulesRolloutRouteGuard } from './legacy/authentication/guards/modules-rollout/modules-rollout-route-guard'
import { MyPartnershipRouteGuard } from './legacy/authentication/guards/my-partnership/my-partnership-route-guard'
import { NewAffiliateRouteGuard } from './legacy/authentication/guards/new-affiliate/new-affiliate-route-guard'
import { PartnersDataRouteGuard } from './legacy/authentication/guards/partners-data/partners-data-route-guard'
import { PartnersPortfolioRouteGuard } from './legacy/authentication/guards/partners-data/partners-portfolio-route-guard'
import { PartnershipOwnerSetupGuard } from './legacy/authentication/guards/partnership-owner-config/partnership-owner-config-guard'
import { AdminAppRouteGuard } from './legacy/authentication/guards/role-type-guard/admin-app-guard'
import { NotAuthorizedComponent } from './legacy/core/components/not-authorized/not-authorized.component'
import { NotificationListComponent } from './legacy/core/components/notification-list/notification-list.component'
import { PartnersRemunerationGuard } from './legacy/features/partners-remuneration/guards/partners-remuneration.guard'
import { sallesFunnelGuard } from './features/sales-funnel/guards/salles-funnel.guard'

export const routes: Routes = [
    {
        path: '**',
        redirectTo: 'partnerhub/home',
    },
    { path: 'logged-user', component: LoggedUser },
    { path: 'validate', component: AccountUserDefinePasswordComponent },
    {
        path: 'notifications',
        component: NotificationListComponent,
    },
    {
        path: 'home',
        loadChildren: () => import('./legacy/features/home/home.module').then(m => m.HomeModule),
        canActivate: [HomeRouteGuard],
    },
    { path: '', pathMatch: 'full', redirectTo: 'home' },
    {
        path: 'stone-account',
        loadChildren: () => import('./legacy/features/stone-account/stone-account.module').then(m => m.StoneAccountModule),
        canActivate: [CreateStoneAccountRouteGuard],
    },
    {
        path: 'affiliate',
        loadChildren: () => import('././legacy/features/affiliation/affiliation.module').then(m => m.AffiliationModule),
        canActivate: [NewAffiliateRouteGuard],
    },
    {
        path: 'account-users',
        loadChildren: () => import('././legacy/features/account-users/account-users.module').then(m => m.AccountUsersModule),
        canActivate: [AccountUserRouteGuard],
    },
    {
        path: 'documents',
        canActivate: [DocumentsRouteGuard],
        loadChildren: () => import('./legacy/features/documents/documents.module').then(m => m.DocumentsModule),
    },
    {
        path: 'permission-control',
        loadChildren: () => import('././legacy/features/permission-control/permission-control.module').then(m => m.PermissionControlModule),
        canActivate: [AdminAppRouteGuard],
    },
    {
        path: 'modules-rollout',
        loadChildren: () => import('././legacy/features/module-release/module-release.module').then(m => m.ModuleReleaseModule),
        canActivate: [AdminAppRouteGuard, ModulesRolloutRouteGuard],
    },
    {
        path: 'partners',
        loadChildren: () => import('./legacy/features/partners/partners.module').then(m => m.PartnersModule),
        canActivate: [PartnersDataRouteGuard],
    },
    {
        path: 'notification-block',
        loadChildren: () => import('./legacy/features/notification-block/notification-block.module').then(m => m.NotificationBlockModule),
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./legacy/features/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [DashboardRouteGuard],
    },
    {
        path: '',
        loadChildren: () =>
            import('./legacy/features/pricing-configurations-overview/pricing-configurations-overview.module').then(
                m => m.PricingConfigurationsOverviewModule,
            ),
    },
    {
        path: '',
        loadChildren: () => import('./legacy/features/applications/applications.module').then(m => m.ApplicationsModule),
        canActivate: [ApplicationRouteGuard],
    },
    {
        path: 'partnership-owner-setup',
        canActivate: [PartnershipOwnerSetupGuard],
        canDeactivate: [PartnershipOwnerSetupGuard],
        loadChildren: () =>
            import('./legacy/features/partnership-owner-setup/partnership-owner-setup.module').then(m => m.PartnershipOwnerSetupModule),
    },
    {
        path: 'integration',
        loadChildren: () => import('./legacy/features/integrations/integrations.module').then(m => m.IntegrationsModule),
        canActivate: [ApplicationApprovalRouteGuard],
    },
    {
        path: 'integration/:id',
        loadChildren: () => import('./legacy/features/integrations/integrations.module').then(m => m.IntegrationsModule),
        canActivate: [ApplicationApprovalRouteGuard],
    },
    {
        path: 'remuneration',
        loadChildren: () =>
            import('./legacy/features/partners-remuneration/partners-remuneration.module').then(m => m.PartnersRemunerationModule),
        canActivate: [PartnersRemunerationGuard],
    },
    { path: 'not-authorized', component: NotAuthorizedComponent },
    {
        path: 'config/profile',
        component: ProfileComponent,
        canActivate: [ProfileGuard],
    },
    { path: 'config/users', component: UsersComponent, canActivate: [UsersGuard] },
    {
        path: 'my-partnership',
        loadComponent: () => import('./features/my-partnership/my-partnership.component').then(c => c.MyPartnershipComponent),
        canActivate: [MyPartnershipRouteGuard],
    },
    {
        path: 'logistics',
        loadComponent: () => import('./features/logistics/logistics.component').then(c => c.LogisticsComponent),
        canActivate: [],
    },
    {
        path: 'my-integration',
        loadComponent: () => import('./features/my-integration/my-integration.component').then(c => c.MyIntegrationComponent),
        canActivate: [IntegrationGuard],
    },
    {
        path: 'price-management',
        loadComponent: () => import('./features/price-management/price-management.component').then(c => c.PriceManagementComponent),
        canActivate: [PriceManagementGuard],
    },
    {
        path: 'tab-bar',
        loadComponent: () => import('./shared/ui/tab-bar/tab-bar.component').then(c => c.TabBarComponent),
    },
    {
        path: 'partners/portfolio',
        loadComponent: () => import('./features/partners/portfolio/portfolio.component').then(c => c.PortfolioComponent),
        canActivate: [PartnersPortfolioRouteGuard],
    },
    {
        path: 'leads/landing-pages',
        loadComponent: () =>
            import('./features/leads/landing-pages-panel/landing-pages-panel.component').then(c => c.LandingPagesPanelComponent),
        canActivate: [LandingPagesPanelRouteGuard],
    },

    {
        path: 'leads/create',
        loadComponent: () => import('./features/leads/create-lead/create-lead.component').then(c => c.CreateLeadComponent),
        canActivate: [CreateLeadRouteGuard],
    },
    {
        path: 'lp/:id',
        loadComponent: () =>
            import('./features/leads/public-landing-page/public-landing-page.component').then(c => c.PublicLandingPageComponent),
        canActivate: [],
        data: { isPublicRoute: true },
    },
    {
        path: 'affiliate/sales-funnel',
        loadComponent: () => import('./features/sales-funnel/pages/sales-funnel/sales-funnel.component').then(c => c.SalesFunnelComponent),
        canActivate: [sallesFunnelGuard],
    },
]
