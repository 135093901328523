export const BusinessModelMap: Record<string, string> = {
    ecommerce_agency: 'Agências de desenvolvimento de e-commerce',
    banking: 'Banking',
    reconciliation: 'Conciliador',
    cooperative: 'Cooperativa',
    fintech: 'Fintech',
    franchises: 'Franquias',
    full_commerce: 'Full commerce',
    gateway: 'Gateway',
    marketplace: 'Marketplace',
    paaa_white_label: 'PaaS White label',
    pep: 'PEP',
    plataform: 'Plataforma',
    ecommerce_plataform: 'Plataforma de Ecommerce',
    psp_subacquirer: 'PSP/Subadquirente',
    resale: 'Revenda',
    saas: 'SaaS',
    software: 'Software',
    client: 'Sou um cliente',
}

export const SalesModelMap: Record<string, string> = {
    outbound: 'Venda Propria',
    inbound: 'Inbound',
    hybrid: 'Híbrido',
    psp_custom: 'PSPs e Customizados',
}

export const ChannelMap: Record<string, string> = {
    hunting: 'Hunting',
    farming: 'Farming',
    selfservice: 'Autosserviço',
    digital: 'Digital',
}
export const TrailMap: Record<string, string> = {
    sales: 'Sales',
    plugin: 'Plugin',
}

export const SegmentsMap: Record<string, string> = {
    1: 'Academias',
    2: 'Automotivo',
    3: 'Beleza',
    4: 'Condominal',
    5: 'Contabilidade',
    6: 'Cripto Ativos',
    7: 'Ecommerce - Loja Online',
    8: 'Educação',
    9: 'Entretenimento/Cashless',
    10: 'Estacionamentos',
    11: 'Fashion',
    12: 'Fidelidade',
    13: 'Financeiro',
    14: 'Food Service',
    15: 'Hotelaria',
    16: 'Igrejas/Templos Religiosos',
    17: 'Industria',
    18: 'Material de Construção',
    19: 'Oficina',
    20: 'Outros',
    21: 'Pet/Vet',
    22: 'Postos de Gasolina',
    23: 'Saúde',
    24: 'Supermercados',
    25: 'Transporte',
    26: 'Varejo Geral',
}
